import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _f6f92f3c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _89d55988 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _76efec86 = () => interopDefault(import('../pages/magic-link/index.vue' /* webpackChunkName: "pages/magic-link/index" */))
const _4a87803c = () => interopDefault(import('../pages/reset-password/index.vue' /* webpackChunkName: "pages/reset-password/index" */))

const _8486170c = () => interopDefault(import('../pages/set-workspace.vue' /* webpackChunkName: "pages/set-workspace" */))
const _31988004 = () => interopDefault(import('../pages/sign-up/index.vue' /* webpackChunkName: "pages/sign-up/index" */))
const _16b6e6b4 = () => interopDefault(import('../pages/store/index.vue' /* webpackChunkName: "pages/store/index" */))
const _56a27428 = () => interopDefault(import('../pages/store/apps/_appName.vue' /* webpackChunkName: "pages/store/apps/_appName" */))
const _20923ecc = () => interopDefault(import('../pages/t/_tenantSlug/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/index" */))
const _5f6dd47a = () => interopDefault(import('../pages/t/_tenantSlug/email-validation/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/email-validation/index" */))
const _1cff9bb4 = () => interopDefault(import('../pages/t/_tenantSlug/login/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/login/index" */))
const _42988f2a = () => interopDefault(import('../pages/t/_tenantSlug/m/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/m/index" */))
const _0146a76d = () => interopDefault(import('../pages/t/_tenantSlug/p/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/p/index" */))
const _56a48d62 = () => interopDefault(import('../pages/t/_tenantSlug/reset-password.vue' /* webpackChunkName: "pages/t/_tenantSlug/reset-password" */))
const _2e628c68 = () => interopDefault(import('../pages/t/_tenantSlug/sign-up/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/sign-up/index" */))
const _6e844ecf = () => interopDefault(import('../pages/t/_tenantSlug/studio/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/index" */))
const _5031a1ae = () => interopDefault(import('../pages/t/_tenantSlug/m/account-configuration.vue' /* webpackChunkName: "pages/t/_tenantSlug/m/account-configuration" */))
const _91350db4 = () => interopDefault(import('../pages/t/_tenantSlug/sso/saml/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/sso/saml/index" */))
const _3014d600 = () => interopDefault(import('../pages/t/_tenantSlug/studio/data/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/data/index" */))
const _05dfe2b6 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings" */))
const _5b2dee8e = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/index" */))
const _31fa8c19 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/app-builder/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/app-builder/index" */))
const _e6de3538 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/authentication/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/authentication/index" */))
const _0d267cde = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/global-parameters-manager/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/global-parameters-manager/index" */))
const _1325a38b = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/integrations/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/integrations/index" */))
const _fc9c2fae = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/internal/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/internal/index" */))
const _7dfa7249 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/system-entities/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/system-entities/index" */))
const _646631f4 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/tokens/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/tokens/index" */))
const _e18f8d00 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/user-groups/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/user-groups/index" */))
const _295f3a32 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/users/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/users/index" */))
const _0b871e64 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/user-groups/new.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/user-groups/new" */))
const _424d0cb3 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/integrations/_id.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/integrations/_id" */))
const _32f2c830 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/user-groups/_id.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/user-groups/_id" */))
const _d119fe4c = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/user-groups/_user-group-form/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/user-groups/_user-group-form/index" */))
const _cfe6b33c = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/user-groups/_user-group-list-item/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/user-groups/_user-group-list-item/index" */))
const _266ded3f = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/users/_active-members-card/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/users/_active-members-card/index" */))
const _3911087e = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/users/_users-data-table-card/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/users/_users-data-table-card/index" */))
const _99dd8eb8 = () => interopDefault(import('../pages/t/_tenantSlug/studio/automations/_id.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/automations/_id" */))
const _92dc97b2 = () => interopDefault(import('../pages/t/_tenantSlug/studio/automations/_id/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/automations/_id/index" */))
const _5cd143a9 = () => interopDefault(import('../pages/t/_tenantSlug/studio/automations/_id/history.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/automations/_id/history" */))
const _e007a02a = () => interopDefault(import('../pages/t/_tenantSlug/studio/data/_id/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/data/_id/index" */))
const _70ba51ec = () => interopDefault(import('../pages/t/_tenantSlug/studio/pages/_id.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/pages/_id" */))
const _77632348 = () => interopDefault(import('../pages/t/_tenantSlug/studio/data/_id/import.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/data/_id/import" */))
const _4ce65cda = () => interopDefault(import('../pages/t/_tenantSlug/m/_viewId/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/m/_viewId/index" */))
const _9434fbc6 = () => interopDefault(import('../pages/t/_tenantSlug/p/_viewId/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/p/_viewId/index" */))
const _03d4720b = () => interopDefault(import('../pages/t/_tenantSlug/m/_viewId/r/new.vue' /* webpackChunkName: "pages/t/_tenantSlug/m/_viewId/r/new" */))
const _6f336559 = () => interopDefault(import('../pages/t/_tenantSlug/m/_viewId/r/_recordId/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/m/_viewId/r/_recordId/index" */))
const _a42483c8 = () => interopDefault(import('../pages/t/_tenantSlug/p/_viewId/r/_recordId/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/p/_viewId/r/_recordId/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _f6f92f3c,
    name: "index___en"
  }, {
    path: "/login",
    component: _89d55988,
    name: "login___pt"
  }, {
    path: "/magic-link",
    component: _76efec86,
    name: "magic-link___pt"
  }, {
    path: "/reset-password",
    component: _4a87803c,
    name: "reset-password___pt"
  }, {
    path: "/reset-password",
    redirect: {"name":"set-tenant___pt"}
  }, {
    path: "/set-workspace",
    component: _8486170c,
    name: "set-workspace___pt"
  }, {
    path: "/sign-up",
    component: _31988004,
    name: "sign-up___pt"
  }, {
    path: "/store",
    component: _16b6e6b4,
    name: "store___pt"
  }, {
    path: "/builder/views",
    redirect: {"name":"studio-pages-id___pt"}
  }, {
    path: "/en/login",
    component: _89d55988,
    name: "login___en"
  }, {
    path: "/en/magic-link",
    component: _76efec86,
    name: "magic-link___en"
  }, {
    path: "/en/reset-password",
    component: _4a87803c,
    name: "reset-password___en"
  }, {
    path: "/en/reset-password",
    redirect: {"name":"set-tenant___en"}
  }, {
    path: "/en/set-workspace",
    component: _8486170c,
    name: "set-workspace___en"
  }, {
    path: "/en/sign-up",
    component: _31988004,
    name: "sign-up___en"
  }, {
    path: "/en/store",
    component: _16b6e6b4,
    name: "store___en"
  }, {
    path: "/en/builder/views",
    redirect: {"name":"studio-pages-id___en"}
  }, {
    path: "/studio/settings/integrations",
    redirect: {"name":"t-tenantSlug-studio-settings-integrations___pt"}
  }, {
    path: "/en/studio/settings/integrations",
    redirect: {"name":"t-tenantSlug-studio-settings-integrations___en"}
  }, {
    path: "/",
    component: _f6f92f3c,
    name: "index___pt"
  }, {
    path: "/en/builder/workflows/:id?",
    redirect: {"name":"studio-automations-id___en"}
  }, {
    path: "/en/store/apps/:appName?",
    component: _56a27428,
    name: "store-apps-appName___en"
  }, {
    path: "/builder/workflows/:id?",
    redirect: {"name":"studio-automations-id___pt"}
  }, {
    path: "/en/t/:tenantSlug",
    component: _20923ecc,
    name: "t-tenantSlug___en"
  }, {
    path: "/store/apps/:appName?",
    component: _56a27428,
    name: "store-apps-appName___pt"
  }, {
    path: "/en/t/:tenantSlug?/email-validation",
    component: _5f6dd47a,
    name: "t-tenantSlug-email-validation___en"
  }, {
    path: "/en/t/:tenantSlug?/login",
    component: _1cff9bb4,
    name: "t-tenantSlug-login___en"
  }, {
    path: "/en/t/:tenantSlug?/m",
    component: _42988f2a,
    name: "t-tenantSlug-m___en"
  }, {
    path: "/en/t/:tenantSlug?/p",
    component: _0146a76d,
    name: "t-tenantSlug-p___en"
  }, {
    path: "/en/t/:tenantSlug?/reset-password",
    component: _56a48d62,
    name: "t-tenantSlug-reset-password___en"
  }, {
    path: "/en/t/:tenantSlug?/sign-up",
    component: _2e628c68,
    name: "t-tenantSlug-sign-up___en"
  }, {
    path: "/en/t/:tenantSlug?/studio",
    component: _6e844ecf,
    name: "t-tenantSlug-studio___en"
  }, {
    path: "/en/t/:tenantSlug?/m/account-configuration",
    component: _5031a1ae,
    name: "t-tenantSlug-m-account-configuration___en"
  }, {
    path: "/en/t/:tenantSlug?/sso/saml",
    component: _91350db4,
    name: "t-tenantSlug-sso-saml___en"
  }, {
    path: "/en/t/:tenantSlug?/studio/data",
    component: _3014d600,
    name: "t-tenantSlug-studio-data___en"
  }, {
    path: "/en/t/:tenantSlug?/studio/settings",
    component: _05dfe2b6,
    children: [{
      path: "",
      component: _5b2dee8e,
      name: "t-tenantSlug-studio-settings___en"
    }, {
      path: "app-builder",
      component: _31fa8c19,
      name: "t-tenantSlug-studio-settings-app-builder___en"
    }, {
      path: "authentication",
      component: _e6de3538,
      name: "t-tenantSlug-studio-settings-authentication___en"
    }, {
      path: "global-parameters-manager",
      component: _0d267cde,
      name: "t-tenantSlug-studio-settings-global-parameters-manager___en"
    }, {
      path: "integrations",
      component: _1325a38b,
      name: "t-tenantSlug-studio-settings-integrations___en"
    }, {
      path: "internal",
      component: _fc9c2fae,
      name: "t-tenantSlug-studio-settings-internal___en"
    }, {
      path: "system-entities",
      component: _7dfa7249,
      name: "t-tenantSlug-studio-settings-system-entities___en"
    }, {
      path: "tokens",
      component: _646631f4,
      name: "t-tenantSlug-studio-settings-tokens___en"
    }, {
      path: "user-groups",
      component: _e18f8d00,
      name: "t-tenantSlug-studio-settings-user-groups___en"
    }, {
      path: "users",
      component: _295f3a32,
      name: "t-tenantSlug-studio-settings-users___en"
    }, {
      path: "user-groups/new",
      component: _0b871e64,
      name: "t-tenantSlug-studio-settings-user-groups-new___en"
    }, {
      path: "integrations/:id",
      component: _424d0cb3,
      name: "t-tenantSlug-studio-settings-integrations-id___en"
    }, {
      path: "user-groups/:id?",
      component: _32f2c830,
      name: "t-tenantSlug-studio-settings-user-groups-id___en"
    }, {
      path: "user-groups/:user-group-form",
      component: _d119fe4c,
      name: "t-tenantSlug-studio-settings-user-groups-user-group-form___en"
    }, {
      path: "user-groups/:user-group-list-item",
      component: _cfe6b33c,
      name: "t-tenantSlug-studio-settings-user-groups-user-group-list-item___en"
    }, {
      path: "users/:active-members-card",
      component: _266ded3f,
      name: "t-tenantSlug-studio-settings-users-active-members-card___en"
    }, {
      path: "users/:users-data-table-card",
      component: _3911087e,
      name: "t-tenantSlug-studio-settings-users-users-data-table-card___en"
    }]
  }, {
    path: "/en/t/:tenantSlug?/studio/automations/:id?",
    component: _99dd8eb8,
    children: [{
      path: "",
      component: _92dc97b2,
      name: "t-tenantSlug-studio-automations-id___en"
    }, {
      path: "history",
      component: _5cd143a9,
      name: "t-tenantSlug-studio-automations-id-history___en"
    }]
  }, {
    path: "/en/t/:tenantSlug?/studio/data/:id",
    component: _e007a02a,
    name: "t-tenantSlug-studio-data-id___en"
  }, {
    path: "/en/t/:tenantSlug?/studio/pages/:id?",
    component: _70ba51ec,
    name: "t-tenantSlug-studio-pages-id___en"
  }, {
    path: "/en/t/:tenantSlug?/studio/data/:id/import",
    component: _77632348,
    name: "t-tenantSlug-studio-data-id-import___en"
  }, {
    path: "/en/t/:tenantSlug?/m/:viewId",
    component: _4ce65cda,
    name: "t-tenantSlug-m-viewId___en"
  }, {
    path: "/en/t/:tenantSlug?/p/:viewId",
    component: _9434fbc6,
    name: "t-tenantSlug-p-viewId___en"
  }, {
    path: "/en/t/:tenantSlug?/m/:viewId/r/new",
    component: _03d4720b,
    name: "t-tenantSlug-m-viewId-r-new___en"
  }, {
    path: "/en/t/:tenantSlug?/m/:viewId/r/:recordId",
    component: _6f336559,
    name: "t-tenantSlug-m-viewId-r-recordId___en"
  }, {
    path: "/en/t/:tenantSlug?/p/:viewId/r/:recordId",
    component: _a42483c8,
    name: "t-tenantSlug-p-viewId-r-recordId___en"
  }, {
    path: "/t/:tenantSlug",
    component: _20923ecc,
    name: "t-tenantSlug___pt"
  }, {
    path: "/t/:tenantSlug?/email-validation",
    component: _5f6dd47a,
    name: "t-tenantSlug-email-validation___pt"
  }, {
    path: "/t/:tenantSlug?/login",
    component: _1cff9bb4,
    name: "t-tenantSlug-login___pt"
  }, {
    path: "/t/:tenantSlug?/m",
    component: _42988f2a,
    name: "t-tenantSlug-m___pt"
  }, {
    path: "/t/:tenantSlug?/p",
    component: _0146a76d,
    name: "t-tenantSlug-p___pt"
  }, {
    path: "/t/:tenantSlug?/reset-password",
    component: _56a48d62,
    name: "t-tenantSlug-reset-password___pt"
  }, {
    path: "/t/:tenantSlug?/sign-up",
    component: _2e628c68,
    name: "t-tenantSlug-sign-up___pt"
  }, {
    path: "/t/:tenantSlug?/studio",
    component: _6e844ecf,
    name: "t-tenantSlug-studio___pt"
  }, {
    path: "/t/:tenantSlug?/m/account-configuration",
    component: _5031a1ae,
    name: "t-tenantSlug-m-account-configuration___pt"
  }, {
    path: "/t/:tenantSlug?/sso/saml",
    component: _91350db4,
    name: "t-tenantSlug-sso-saml___pt"
  }, {
    path: "/t/:tenantSlug?/studio/data",
    component: _3014d600,
    name: "t-tenantSlug-studio-data___pt"
  }, {
    path: "/t/:tenantSlug?/studio/settings",
    component: _05dfe2b6,
    children: [{
      path: "",
      component: _5b2dee8e,
      name: "t-tenantSlug-studio-settings___pt"
    }, {
      path: "app-builder",
      component: _31fa8c19,
      name: "t-tenantSlug-studio-settings-app-builder___pt"
    }, {
      path: "authentication",
      component: _e6de3538,
      name: "t-tenantSlug-studio-settings-authentication___pt"
    }, {
      path: "global-parameters-manager",
      component: _0d267cde,
      name: "t-tenantSlug-studio-settings-global-parameters-manager___pt"
    }, {
      path: "integrations",
      component: _1325a38b,
      name: "t-tenantSlug-studio-settings-integrations___pt"
    }, {
      path: "internal",
      component: _fc9c2fae,
      name: "t-tenantSlug-studio-settings-internal___pt"
    }, {
      path: "system-entities",
      component: _7dfa7249,
      name: "t-tenantSlug-studio-settings-system-entities___pt"
    }, {
      path: "tokens",
      component: _646631f4,
      name: "t-tenantSlug-studio-settings-tokens___pt"
    }, {
      path: "user-groups",
      component: _e18f8d00,
      name: "t-tenantSlug-studio-settings-user-groups___pt"
    }, {
      path: "users",
      component: _295f3a32,
      name: "t-tenantSlug-studio-settings-users___pt"
    }, {
      path: "user-groups/new",
      component: _0b871e64,
      name: "t-tenantSlug-studio-settings-user-groups-new___pt"
    }, {
      path: "integrations/:id",
      component: _424d0cb3,
      name: "t-tenantSlug-studio-settings-integrations-id___pt"
    }, {
      path: "user-groups/:id?",
      component: _32f2c830,
      name: "t-tenantSlug-studio-settings-user-groups-id___pt"
    }, {
      path: "user-groups/:user-group-form",
      component: _d119fe4c,
      name: "t-tenantSlug-studio-settings-user-groups-user-group-form___pt"
    }, {
      path: "user-groups/:user-group-list-item",
      component: _cfe6b33c,
      name: "t-tenantSlug-studio-settings-user-groups-user-group-list-item___pt"
    }, {
      path: "users/:active-members-card",
      component: _266ded3f,
      name: "t-tenantSlug-studio-settings-users-active-members-card___pt"
    }, {
      path: "users/:users-data-table-card",
      component: _3911087e,
      name: "t-tenantSlug-studio-settings-users-users-data-table-card___pt"
    }]
  }, {
    path: "/t/:tenantSlug?/studio/automations/:id?",
    component: _99dd8eb8,
    children: [{
      path: "",
      component: _92dc97b2,
      name: "t-tenantSlug-studio-automations-id___pt"
    }, {
      path: "history",
      component: _5cd143a9,
      name: "t-tenantSlug-studio-automations-id-history___pt"
    }]
  }, {
    path: "/t/:tenantSlug?/studio/data/:id",
    component: _e007a02a,
    name: "t-tenantSlug-studio-data-id___pt"
  }, {
    path: "/t/:tenantSlug?/studio/pages/:id?",
    component: _70ba51ec,
    name: "t-tenantSlug-studio-pages-id___pt"
  }, {
    path: "/t/:tenantSlug?/studio/data/:id/import",
    component: _77632348,
    name: "t-tenantSlug-studio-data-id-import___pt"
  }, {
    path: "/t/:tenantSlug?/m/:viewId",
    component: _4ce65cda,
    name: "t-tenantSlug-m-viewId___pt"
  }, {
    path: "/t/:tenantSlug?/p/:viewId",
    component: _9434fbc6,
    name: "t-tenantSlug-p-viewId___pt"
  }, {
    path: "/t/:tenantSlug?/m/:viewId/r/new",
    component: _03d4720b,
    name: "t-tenantSlug-m-viewId-r-new___pt"
  }, {
    path: "/t/:tenantSlug?/m/:viewId/r/:recordId",
    component: _6f336559,
    name: "t-tenantSlug-m-viewId-r-recordId___pt"
  }, {
    path: "/t/:tenantSlug?/p/:viewId/r/:recordId",
    component: _a42483c8,
    name: "t-tenantSlug-p-viewId-r-recordId___pt"
  }, {
    path: "/:locale(en)?/builder/:after(.*)",
    redirect: (to) => ({ path: `${to.params.locale}/studio/${to.params.after}` })
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
